<template>
	<div class="app-search">
		<input
			v-model="searchInput"
			type="text"
			class="app-search__input"
			id="main_header_search"
			:placeholder="$t('placeholders.courses_search_placeholder')"
			autocomplete="off"
			v-on:keyup.enter="search()"
		/>
		<a :href="searchQuery" class="app-search__submit">
			<span class="visually-hidden">{{ $t('buttons.search') }}</span>
		</a>
		<button v-if="false" class="main-header-search-remove-btn remove-btn">
			<span class="visually-hidden">{{ $t('buttons.clear') }}</span>
		</button>
	</div>
</template>

<script>
import axios from 'axios';


export default {
	name: 'AppSearch',

	props: {
		search_url: String,
	},

	data() {
		return {
			searchInput: '',
			loading: true
		};
	},

	computed: {
		searchQuery() {
			if (this.searchInput) {
				return this.search_url + '?q=' + this.searchInput;
			}
		}
	},

	methods: {
		search1(q) {
			axios.request({
				responseType: 'json',
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					'X-CSRF-Token': document
					.querySelector('meta[name="csrf-token"]')
					.getAttribute('content')
				},
				url: this.search_url,
				method: 'GET',
				data: {
					q: q
				}
			});
		},

		search() {
			if (this.searchInput) {
				window.location.href = this.search_url + '?q=' + this.searchInput;
			}
		},
	},
};
</script>
